/*
 * @Author: gaojiahao 3055320775@qq.com
 * @Date: 2023-11-08 09:10:43
 * @LastEditors: gaojiahao 3055320775@qq.com
 * @LastEditTime: 2024-08-21 17:53:03
 * @FilePath: \属地三期\src\api\peizhi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

export const peizhi = () => {
  // 全局操作层
  // 是不是陕西省委的
  window.localStorage.setItem("isWangxinban", false); // 1 || 0
  window.localStorage.setItem("actionUrl", "module-file/ossFile/upload"); // 文件上传地址,不是省委(本地需要在路径前加上/,线上不需要)
  // window.localStorage.setItem("isWangxinban", true);  // 1 || 0
  // window.localStorage.setItem("actionUrl", 'module-file/locality/upload');  // 文件上传地址,省委
  // 大屏跳转
  window.localStorage.setItem("daping_url", "http://192.168.50.253:8083"); //对应本地大屏跳转
  // window.localStorage.setItem("daping_url", 'http://10.5.4.19:10280/screen');  //对应正式省委
  // window.localStorage.setItem("daping_url", 'http://172.16.2.92:82');  //对应测试省委

  //----------------------------------------------------------------------------------------------------
  //属地更新迭代------排查功能-----是否显示菜单(前端写死的,现在已改为接口获取)
  window.localStorage.setItem("is_show_update", true);
  //---------------------------------------------------------------------------------------------------
  // 请求配置层
  // 业务接口
  const baseURL = {
    // 1: '/api',// 118 测试   代理跨域
    1: "http://10.1.128.226:8102/",
    // 1:'http://10.1.128.226:8201/', //联调和恩荣测试
    2: "https://api.sd.wangxinban.cn/", // 发布
    // 2: "http://10.1.0.104:8102", // 发布 火山云
    3: "http://10.5.4.19:65002/", // 网信办正式
    4: "http://172.16.2.92:8102/", // 网信办测试  给省委打包发这个
    // 2: "http://192.168.50.207:8210/", // 开发   先保留下来说不定会有点小用
  };
  // 大后台接口  || 登陆
  const loginBaseURL = {
    // 1: "http://api.admin.konne.cn/", //测试
    1: "http://10.1.128.241:8888/",
    2: "https://api.juzhen.yuqing.cn", // 发 布
    3: "http://10.5.4.19:65003", // 网信办正式
    4: "http://172.16.2.91:8888", // 网信办测试  给省委打包发这个
  };

  // 登陆页获取domain
  const domain = {
    1: "10.1.128.226",
    // 1: "apanage.konne.cn", // 本地用 || 118用 || 测试
    2: "sd.wangxinban.cn", // 线上地址用  ||  网信办
    3: window.location.host,
  };
  // 参数抛出层
  return {
    baseURL: baseURL[2], // 业务接口
    loginBaseURL: loginBaseURL[2], //大后台接口|| 登陆
    //  domain:domain[3], // 登陆页获取domain
    domain: domain[2], // 登陆页获取domain
  };
  //注意登录接口
};
