<template>
  <div style="height: 100%">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script> 
import { mapMutations } from 'vuex';
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function() {
        this.isRouterAlive = true;
      });
    },
  },
  mounted(){
  }
};
</script>
<style lang="scss" scoped>
.v-modal {
  z-index: 0;
}
.zb_layout_stop {
  background-color: #fdebec !important;
  border-color: #fbd6d9 !important;
  color: #ea3342;
}
.zb_layout_stop .el-icon-info {
  color: #ea3342 !important;
}
::v-deep .el-loading-mask {
  z-index: 10 !important;
}
</style>
<style>
/* .atooltip {
  font-size: 0.1647rem !important;
  padding: 0.0941rem !important;
  line-height: 0.2353rem !important;
  border-radius: 0.0471rem !important;
  left: 2.9412rem !important;
}
.atooltip .popper__arrow{
  display: none;
} */
</style>