var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout",staticStyle:{"min-width":"101.5625rem"}},[(
      _vm.$route.path != '/bigdata/harmfulInformation' &&
        _vm.$route.path != '/bigdata/misrepresentation' &&
        _vm.$route.path != '/bigdata/exceptionFilter'
    )?_c('div',{class:_vm.isCollapse ? 'layout-aside-two' : 'layout-aside'},[_c('Aside',{attrs:{"isCollapse":_vm.isCollapse}})],1):_vm._e(),_c('div',{class:[
      _vm.isCollapse ? 'layout-header-two' : 'layout-header',
      _vm.$store.state.openNewUrl &&
      (_vm.$route.path == '/bigdata/harmfulInformation' ||
        _vm.$route.path == '/bigdata/misrepresentation' ||
        _vm.$route.path == '/bigdata/exceptionFilter')
        ? 'openNewUrlStyle'
        : '',
    ],staticStyle:{"min-width":"37.5rem"}},[_c('Header',{ref:"header",on:{"change":_vm.change}})],1),_c('div',{class:[
      _vm.isCollapse ? 'layout-main-two' : 'layout-main',
      _vm.$store.state.openNewUrl &&
      (_vm.$route.path == '/bigdata/harmfulInformation' ||
        _vm.$route.path == '/bigdata/misrepresentation' ||
        _vm.$route.path == '/bigdata/exceptionFilter')
        ? 'openNewUrlStyle'
        : '',
    ]},[_c('router-view')],1),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.loginTipsShow,"width":"40%","before-close":_vm.loginTipsClose},on:{"update:visible":function($event){_vm.loginTipsShow=$event}}},[_c('div',{attrs:{"id":"zb_layout_tips_main"}},[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.loginTips.url),expression:"loginTips.url"}],attrs:{"src":_vm.loginTips.url}}),_c('h2',{domProps:{"innerHTML":_vm._s(_vm.loginTips.title)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.loginTips.text)}})],1),_c('span',{staticClass:"zb_layout_tips dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.loginTipsClose}},[_vm._v("确 定")])],1)]),_c('ExportDialog',{ref:"exportDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }