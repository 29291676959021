import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* 错误提示抑制 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};
/* 公共页面 */
import Layout from "@/components/Layout";
/* 路由配置 */
export const constantRoutes = [
  {
    path: "/",
    redirect: "/transfer",
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/transfer",
    component: () => import("@/views/login/transfer"),
    hidden: true,
  },
  {
    path: "/WeChat",
    component: () => import("@/views/login/WeChat"),
    hidden: true,
  },
  {
    path: "/daping",
    name: "属地大屏",
    component: () =>
      import("../views/daping/index.vue"),
    hidden: true,
    meta: {
      activePath: "/daping",
    },
  },
  {
    path: "/cwdaping",
    name: "错误表述大屏",
    component: () =>
      import("../views/daping/cuowu/index.vue"),
    hidden: true,
    meta: {
      activePath: "/cwdaping",
    },
  },
  {
    path: "/shudi",
    name: "属地监测报告",
    component: () => import("@/views/shift/index.vue")
  },
  {
    path: "/summary",
    name: "手机端专项行动",
    component: () => import("@/views/shift/summary.vue")
  },
  {
    path: "/harmfal",
    name: "有害预警",
    component: () => import("@/views/shift/harmful.vue")
  },
  {
    path: "/index",
    component: Layout,
    redirect: "/index",

    children: [
      {
        path: "/download",
        name: "下载中心",
        component: () => import("@/views/index/download"),
        meta: {
          title: "首页",
          activePath: "/index",
        },
      },
      {
        path: "/index",
        name: "首页",
        component: () => import("@/views/index/index"),
        meta: {
          title: "首页",
          activePath: "/index",
        },
      },
      {
        path: "/cancelAccount",
        name: "已注销台账",
        component: () => import("@/views/platformaccount/cancelAccount/index"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/appAccount",
        name: "APP台账",
        component: () => import("@/views/platformaccount/appAccount/index"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/appDetails",
        name: "APP信息详情",
        component: () => import("@/views/platformaccount/appAccount/detail"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/appEdit",
        name: "APP信息编辑",
        component: () => import("@/views/platformaccount/appAccount/edit"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/platformaccount",
        name: "平台台账",
        component: () => import("@/views/platformaccount/index.vue"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/newAccount",
        name: "新增台账账号",
        component: () => import("@/views/platformaccount/newAccount.vue"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/accountDetail",
        name: "账号详情",
        component: () => import("@/views/platformaccount/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/platformaccount",
        },
      },
      {
        path: "/publishingcontent",
        name: "发布内容",
        component: () => import("@/views/publishingcontent/index.vue"),
        hidden: true,
        meta: {
          activePath: "/publishingcontent",
        },
      },
      {
        path: "/publishingcontentDetai",
        name: "发布内容详情",
        component: () => import("@/views/publishingcontent/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/publishingcontent",
        },
      },
      {
        path: "/harmful",
        name: "有害信息",
        component: () => import("@/views/harmful/index.vue"),
        hidden: true,
        meta: {
          activePath: "/harmful",
        },
      },
      {
        path: "/harmfulDetail",
        name: "有害信息详情",
        component: () => import("@/views/harmful/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/harmful",
        },
      },
      {
        path: "/Problem",
        name: "问题平台",
        component: () => import("@/views/Problem/index.vue"),
        hidden: true,
        meta: {
          activePath: "/Problem",
        },
      },
      {
        path: "/questionDetail",
        name: "问题平台详情",
        component: () => import("@/views/Problem/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/Problem",
        },
      },
      {
        path: "/completed",
        name: "问题平台 处置已完成",
        component: () => import("@/views/Problem/completed.vue"),
        hidden: true,
        meta: {
          activePath: "/Problem",
        },
      },
      {
        path: "/chuzhijilu",
        name: "处置记录",
        component: () => import("@/views/Problem/chuzhijilu.vue"),
        hidden: true,
        meta: {
          activePath: "/Problem",
        },
      },
      {
        path: "/special",
        name: "专项行动",
        component: () => import("@/views/special/index.vue"),
        hidden: true,
        meta: {
          activePath: "/special",
        },
      },
      {
        path: "/addspecial",
        name: "专项行动页面新增/修改",
        component: () => import("@/views/special/addspecial.vue"),
        hidden: true,
        meta: {
          activePath: "/special",
        },
      },
      {
        path: "/topicListDetails",
        name: "专项报告事件概括/全部信息 ",
        component: () => import("@/views/special/topicListDetails.vue"),
        hidden: true,
        meta: {
          activePath: "/special",
        },
      },
      {
        path: "/moretopics",
        name: "更多话题",
        component: () => import("@/views/special/moretopics.vue"),
        hidden: true,
        meta: {
          activePath: "/special",
        },
      },
      {
        path: "/specialDetail",
        name: "信息详情",
        component: () => import("@/views/special/specialDetail.vue"),
        hidden: true,
        meta: {
          activePath: "/special",
        },
      },
      {
        path: "/error",
        name: "错误表述",
        component: () => import("@/views/error/index.vue"),
        hidden: true,
        meta: {
          activePath: "/error",
        },
      },
      {
        path: "/errorDetail",
        name: "错误表述详情",
        component: () => import("@/views/error/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/error",
        },
      },
      {
        path: "/monitor",
        name: "信息监测",
        component: () => import("@/views/monitor/index.vue"),
        // hidden: true,
        meta: {
          activePath: "/monitor",
          keepAlive: true
        },
      },
      {
        path: "/monitorDetail",
        name: "信息监测详情",
        component: () => import("@/views/monitor/monitorDetail.vue"),
        hidden: true,
        meta: {
          activePath: "/monitor",
        },
      },
      {
        path: "/territorialreport",
        name: "属地报告",
        component: () => import("@/views/territorialreport/index.vue"),
        hidden: true,
        meta: {
          activePath: "/territorialreport",
        },
      },
      {
        path: "/settings",
        name: "系统设置",
        component: () => import("@/views/settings/index.vue"),
        hidden: true,
        meta: {
          activePath: "/settings",
        },
      },
      {
        path: "/settings/harmfulDetail",
        name: "系统设置有害信息详情",
        component: () =>
          import("@/views/settings/components/harmful/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/settings",
        },
      },
      {
        path: "/settings/errorDetail",
        name: "系统设置错误表述详情",
        component: () => import("@/views/settings/components/error/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/settings",
        },
      },
      {
        path: "/settings/monitorDetail",
        name: "系统设置监测信息详情",
        component: () =>
          import("@/views/settings/components/monitor/monitorDetail.vue"),
        hidden: true,
        meta: {
          activePath: "/settings",
        },
      },
      {
        path: "/settings/specialDetail",
        name: "系统设置专项行动详情",
        component: () =>
          import("@/views/settings/components/special/specialDetail.vue"),
        hidden: true,
        meta: {
          activePath: "/settings",
        },
      },
      {
        path: "/settings/cwbsckDetail",
        name: "系统设置错误表述词库",
        component: () =>
          import("@/views/settings/cwbsckDetail.vue"),
        hidden: true,
        meta: {
          activePath: "/settings", 
        },
      },
      {
        path: "/settings/wordsOperationHistory",
        name: "词库操作历史",
        component: () =>
          import("@/views/settings/wordsOperationHistory.vue"),
        hidden: true,
        meta: {
          activePath: "/settings", 
        },
      },
      {
        path: "/bigdata",
        name: "大数据研判",
        component: () => import("@/views/bigdata/index.vue"),
        redirect: "/bigdata/harmfulInformation",
        hidden: true,

        children: [
          {
            path: "/bigdata/harmfulInformation",
            name: "有害信息研判",
            component: () => import("@/views/bigdata/harmfulInformation.vue"),
            hidden: true,
          },
          {
            path: "/bigdata/misrepresentation",
            name: "错误表述研判",
            component: () => import("@/views/bigdata/misrepresentation.vue"),
            hidden: true,
          },
          {
            path: "/bigdata/exceptionFilter",
            name: "异常状态",
            component: () => import("@/views/bigdata/exceptionFilter.vue"),
            hidden: true,
          },
        ],
      },
      {
        path: "/disposeCount",
        name: "处置结果统计",
        component: () =>
          import("../views/disposeCount/index.vue"),
        hidden: true,
        meta: {
          activePath: "/disposeCount",
        },
      },
      {
        path: "/newCheck",
        name: "新的排查",
        component: () =>
          import("../views/newCheck/index.vue"),
        hidden: true,
        meta: {
          activePath: "/newCheck",
        },
      },
      {
        path: "/myKeyWord",
        name: "我的词库",
        component: () =>
          import("../views/newCheck/myKeyWord.vue"),
        hidden: true,
        meta: {
          activePath: "/newCheck",
        },
      },
      {
        path: "/troubleshoot",
        name: "发文排查",
        component: () =>
          import("../views/troubleshoot/home.vue"),
        hidden: true,
        meta: {
          activePath: "/troubleshoot",
        },
      },
      // {
      //   path: "/troubleshoot/home",
      //   name: "发文排查",
      //   component: () =>
      //     import("../views/troubleshoot/home.vue"),
      //   hidden: true,
      //   meta: {
      //     activePath: "/troubleshoot",
      //   },
      // },
      {
        path: "/zwfwMonitor",
        name: "政务发文监测",
        component: () =>
          import("../views/zwfwMonitor/index.vue"),
        hidden: true,
        meta: {
          activePath: "/zwfwMonitor",
        },
      },
      {
        path: "/errLog",
        name: "错误表述历史巡查",
        component: () =>
          import("../views/errLog/index.vue"),
        hidden: true,
        meta: {
          activePath: "/errLog",
        },
      },
      {
        path: "/troubleshoot/test",
        name: "校对页面",
        component: () =>
          import("../views/troubleshoot/test.vue"),
        hidden: true,
        meta: {
          activePath: "/troubleshoot",
        },
      },
      {
        path: "/troubleshoot/detail",
        name: "分析详情",
        component: () =>
          import("../views/troubleshoot/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/troubleshoot",
        },
      },
      {
        path: "/account",
        name: "用户管理",
        component: () =>
          import("../views/account/index.vue"),
        hidden: true,
        meta: {
          activePath: "/account",
        },
      },
      {
        path: "/account/detail",
        name: "用户管理详情",
        component: () =>
          import("../views/account/detail.vue"),
        hidden: true,
        meta: {
          activePath: "/account",
        },
      },
      {
        path: "/bigPing",
        name: "可视化大屏",
        component: () =>
          import("../views/bigPing/index.vue"),
        hidden: true,
        meta: {
          activePath: "/bigPing",
        },
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  // 404 page must be placed at the end !!!
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];
/* 路由跳转时 滚动条归0 */
const createRouter = () =>
  new Router({
    scrollBehavior: () => ({
      y: 0,
    }),
    routes: constantRoutes,
  });
const router = createRouter();
// 路由守卫
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.name) {
    document.title = to.name;
  }
  if (to.path == '/bigPing') {//跳转大屏
    let en = escape(localStorage.getItem('user'));//加密
    let de = unescape(en);//解密
    window.open(
      // 'http://192.168.50.253:8083/#/'  // 测试用 || 本地 ps; 随时会变
      // `http://172.16.2.92:82/#/?userInfo=${localStorage.getItem('user')}`  //对应8888端口号
      // `http://10.5.4.19:10280/screen/#/?userInfo=${localStorage.getItem('user')}` //对应65003端口号
      // `${localStorage.getItem('daping_url')}/#/?userInfo=${localStorage.getItem('user')}` //对应65003端口号
      `${localStorage.getItem('daping_url')}/#/?userInfo=${en}` //对应65003端口号
    )
    return
  }
  next();
});
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
